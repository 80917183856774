// src/components/Game.tsx
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { format } from 'date-fns';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShuffle, faCheck } from '@fortawesome/free-solid-svg-icons';

interface GameProps {
    isDaily: boolean;
    gameId?: number;
    showStats: boolean;
    setShowStats: (show: boolean) => void;
    gameStats: GameStats;
    setGameStats: (stats: GameStats) => void;
  }

export interface GameStats {
  streak: number;
  lastPlayed: string;
  gamesPlayed: number;
  wordsFound: number;
  highestScore: number;
}

interface Puzzle {
  id: number;
  tiles: string[];
  quartiles: string[];
  validWords: string[];
}

interface ResultsModalProps {
  score: number;
  rank: string;
  wordsFound: string[];
  streak: number;
  onClose: () => void;
  onShare: () => void;
  onKeepPlaying: () => void;
  isDaily: boolean;
  gameId?: number;
}

// Animations and Styles
const styles = `
@keyframes appear {
  0% { opacity: 0; transform: translate(-50%, -50%) scale(0.5); }
  20% { opacity: 1; transform: translate(-50%, -50%) scale(1.1); }
  40% { transform: translate(-50%, -50%) scale(0.95); }
  60% { transform: translate(-50%, -50%) scale(1.05); }
  100% { transform: translate(-50%, -50%) scale(1); }
}
.animate-appear {
  animation: appear 0.4s ease-out forwards;
}

.tile-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
}

.quartile-tile {
  border: 2px solid #3b82f6;
  color: #3b82f6;
}
`;


interface StatsModalProps {
    onClose: () => void;
    stats: GameStats;
  }
  
  const StatsModal: React.FC<StatsModalProps> = ({ onClose, stats }) => {
    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
        <div className="bg-white rounded-xl p-6 max-w-md w-full mx-4">
          <button 
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
          >
            ×
          </button>
  
          <div className="text-center mb-8">
            <h2 className="text-2xl font-bold mb-4">Statistics</h2>
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="text-3xl font-bold text-emerald-500">{stats.streak}</div>
                <div className="text-gray-500">Current Streak</div>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="text-3xl font-bold text-emerald-500">{stats.highestScore}</div>
                <div className="text-gray-500">Highest Score</div>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="text-3xl font-bold text-emerald-500">{stats.gamesPlayed}</div>
                <div className="text-gray-500">Games Played</div>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="text-3xl font-bold text-emerald-500">{stats.wordsFound}</div>
                <div className="text-gray-500">Total Words</div>
              </div>
            </div>
          </div>
  
          <button
            onClick={onClose}
            className="w-full bg-emerald-500 text-white py-3 rounded-lg"
          >
            Close
          </button>
        </div>
      </div>
    );
  };

// Helper Components
const CelebrationRays: React.FC = () => (
  <div className="absolute inset-0">
    {[...Array(8)].map((_, i) => (
      <div
        key={i}
        className="absolute w-2 h-0.5 bg-emerald-500"
        style={{
          left: '50%',
          top: '50%',
          transform: `rotate(${i * 45}deg) translateX(100%)`,
          transformOrigin: '0% 50%'
        }}
      />
    ))}
  </div>
);

// Results Modal Component
const ResultsModal: React.FC<ResultsModalProps> = ({
    score,
    rank,
    wordsFound,
    streak,
    onClose,
    onShare,
    onKeepPlaying,
    isDaily,
    gameId
  }) => {
    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
        <div className="bg-white rounded-xl p-6 max-w-md w-full mx-4">
          <button 
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
          >
            ×
          </button>
          
          <div className="text-center mb-8">
            <div className="bg-emerald-500 text-white w-24 h-24 rounded-xl mx-auto mb-4 flex items-center justify-center flex-col">
              <div className="text-3xl font-bold">{score}</div>
              <div>{rank}</div>
            </div>
            <h2 className="text-2xl font-bold">Congratulations!</h2>
            <p className="text-gray-500">
              {isDaily ? "You completed today's puzzle!" : `You completed puzzle #${gameId}!`}
            </p>
          </div>
  
          <div className="space-y-4 mb-6">
            <div className="flex justify-between items-center py-2 border-b">
              <span className="text-gray-500">Words Found</span>
              <span className="font-medium">{wordsFound.length}</span>
            </div>
            {isDaily && (
              <div className="flex justify-between items-center py-2 border-b">
                <span className="text-gray-500">Current Streak</span>
                <span className="font-medium">{streak} days</span>
              </div>
            )}
          </div>
  
          {isDaily ? (
            <>
              <button
                onClick={() => window.location.href = "/?mode=unlimited"}
                className="w-full bg-emerald-500 text-white py-3 rounded-lg mb-4"
              >
                Play Again (Unlimited)
              </button>
              <button
                onClick={onClose}
                className="w-full bg-gray-200 text-gray-700 py-3 rounded-lg mb-4"
              >
                Close
              </button>
            </>
          ) : (
            <button
              onClick={onKeepPlaying}
              className="w-full bg-emerald-500 text-white py-3 rounded-lg mb-4"
            >
              Play Again
            </button>
          )}
  
          <div className="flex justify-between">
            <button 
              onClick={onShare}
              className="text-blue-500"
            >
              Share
            </button>
          </div>
        </div>
      </div>
    );
  };

// New Game Button Component
const NewGameButton: React.FC = () => {
  const handleNewGame = () => {
    const newGameId = Math.floor(Math.random() * 5000) + 5000;
    window.location.href = `/?mode=unlimited&gameId=${newGameId}`;
  };

  return (
    <button
      onClick={handleNewGame}
      className="w-full bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 mt-4"
    >
      New Game
    </button>
  );
};

// Main Game Component
const Game: React.FC<GameProps> = ({ 
    isDaily, 
    gameId, 
    showStats, 
    setShowStats,
    gameStats,
    setGameStats 
  }) => {
  const [puzzle, setPuzzle] = useState<Puzzle | null>(null);
  const [selectedTiles, setSelectedTiles] = useState<string[]>([]);
  const [wordsFound, setWordsFound] = useState<string[]>([]);
  const [score, setScore] = useState(0);
  const [rank, setRank] = useState('Novice');
  const [quartilesFound, setQuartilesFound] = useState<string[]>([]);
  const [showResults, setShowResults] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [activeTiles, setActiveTiles] = useState<string[]>([]);
  const [quartileTiles, setQuartileTiles] = useState<string[]>([]);
  const [showWordEffect, setShowWordEffect] = useState(false);
  const [currentWord, setCurrentWord] = useState('');
  const [isStateLoaded, setIsStateLoaded] = useState(false);

  useEffect(() => {
    const loadPuzzle = async () => {
      let currentId: number;
      if (isDaily) {
        const start = new Date('2024-12-04').getTime();
        const now = new Date().getTime();
        currentId = Math.floor((now - start) / (1000 * 60 * 60 * 24)) + 1;
      } else {
        if (!gameId) {
          const randomId = Math.floor(Math.random() * 5000) + 5000;
          window.location.href = `/?mode=unlimited&gameId=${randomId}`;
          return;
        }
        currentId = gameId;
      }
  
      const fileIndex = Math.floor(currentId / 200);
      try {
        const response = await fetch(`/data/puzzles${(fileIndex*3456)}.json`);
        const puzzles = await response.json();
        
        const puzzleData = puzzles.puzzles.find((p: Puzzle) => p.id === currentId);
        if (!puzzleData) {
          console.error('Puzzle not found:', currentId);
          return;
        }
        
        setPuzzle(puzzleData);
        
        // Check for saved state before setting tiles
        const today = format(new Date(), 'yyyy-MM-dd');
        const savedState = isDaily ? Cookies.get(`quartiles_daily_game_${today}`) : null;
        
        if (savedState) {
          const state = JSON.parse(savedState);
          // Filter out all quartile tiles that have been found
          const foundQuartileTiles = state.quartileTiles?.flat() || [];
            const remainingTiles = puzzleData.tiles.filter(
            (tile: string) => !foundQuartileTiles.includes(tile)
            );
          setActiveTiles(remainingTiles);
        } else {
          setActiveTiles(puzzleData.tiles);
        }
        
      } catch (error) {
        console.error('Failed to load puzzle:', error);
      }
    };
  
    loadPuzzle();
  }, [gameId, isDaily]);


  useEffect(() => {
    const loadGameState = async () => {
      if (!isDaily) return;
      
      const today = format(new Date(), 'yyyy-MM-dd');
      const savedState = Cookies.get(`quartiles_daily_game_${today}`);
      
      if (savedState) {
        const state = JSON.parse(savedState);
        setWordsFound(state.wordsFound || []);
        setScore(state.score || 0);
        setRank(state.rank || 'Novice');
        setQuartilesFound(state.quartilesFound || []);
        setQuartileTiles(state.quartileTiles || []);
        setIsGameCompleted(state.isGameCompleted || false);
        
        if (state.isGameCompleted) {
          setShowResults(true);
        }
      }
      setIsStateLoaded(true);
    };
  
    loadGameState();
  }, [isDaily]);


  const saveGameState = () => {
    if (!isDaily) return;
    
    const today = format(new Date(), 'yyyy-MM-dd');
    const state = {
      wordsFound,
      score,
      rank,
      quartilesFound,
      activeTiles,
      quartileTiles,
      isGameCompleted
    };
    console.log(state)
    console.log(today)
    Cookies.set(`quartiles_daily_game_${today}`, JSON.stringify(state), { expires: 1 });
  };

  


  useEffect(() => {
    const savedStats = Cookies.get('quartiles_stats');
    if (savedStats) {
      setGameStats(JSON.parse(savedStats));
    }
  }, []);

  useEffect(() => {
    if (!isStateLoaded) return;
    saveGameState();
  }, [wordsFound, score, rank, quartilesFound, activeTiles, quartileTiles, isGameCompleted]);

  const handleTileClick = (tile: string) => {
    if (selectedTiles.includes(tile)) {
      setSelectedTiles(selectedTiles.filter(t => t !== tile));
    } else if (selectedTiles.length < 4) {
      setSelectedTiles([...selectedTiles, tile]);
    }
  };

  const shuffleTiles = () => {
    setActiveTiles(prev => [...prev].sort(() => Math.random() - 0.5));
  };

  const rearrangeTilesAfterQuartile = (foundTiles: string[]) => {
    const newActiveTiles = activeTiles.filter(tile => !foundTiles.includes(tile));
    setQuartileTiles([...quartileTiles, ...foundTiles]);
    setActiveTiles(newActiveTiles);
  };

  const handleSubmit = () => {
    if (!puzzle || selectedTiles.length === 0) return;

    const word = selectedTiles.join('');
    if (puzzle.validWords.includes(word) && !wordsFound.includes(word)) {
      setCurrentWord(word);
      setShowWordEffect(true);
      
      const wordScore = {
        1: 1,
        2: 2,
        3: 4,
        4: 8
      }[selectedTiles.length] || 0;
      
      let newScore = score + wordScore;
      
      if (selectedTiles.length === 4 && puzzle.quartiles.includes(word)) {
        const newQuartilesFound = [...quartilesFound, word];
        setQuartilesFound(newQuartilesFound);
        rearrangeTilesAfterQuartile(selectedTiles);
        
        if (newQuartilesFound.length === puzzle.quartiles.length) {
          newScore += 40;
          setIsGameCompleted(true);
          setTimeout(() => {
            updateStats(newScore);
            setShowResults(true);
          }, 1500);
        }
      }
      
      setTimeout(() => {
        setWordsFound([...wordsFound, word]);
        setScore(newScore);
        setShowWordEffect(false);
        setRank(calculateRank(newScore));
      }, 1500);
    }
    
    setSelectedTiles([]);
  };

  const calculateRank = (score: number): string => {
    if (score >= 100) return 'Expert';
    if (score >= 35) return 'Builder';
    if (score >= 15) return 'Apprentice';
    if (score >= 5) return 'Novice';
    return 'Beginner';
  };

  const updateStats = (finalScore: number) => {
    const today = format(new Date(), 'yyyy-MM-dd');
    const newStats = { ...gameStats };

    if (isDaily) {
      if (newStats.lastPlayed !== today) {
        const yesterday = format(new Date().setDate(new Date().getDate() - 1), 'yyyy-MM-dd');
        newStats.streak = newStats.lastPlayed === yesterday ? newStats.streak + 1 : 1;
      }
      newStats.lastPlayed = today;
    }

    newStats.gamesPlayed++;
    newStats.wordsFound += wordsFound.length;
    newStats.highestScore = Math.max(newStats.highestScore, finalScore);

    setGameStats(newStats);
    Cookies.set('quartiles_stats', JSON.stringify(newStats), { expires: 365 });
  };

  const handleShare = () => {
    const mode = isDaily ? 'daily' : 'unlimited';
    const currentId = gameId || generateGameId(isDaily);
    const shareUrl = isDaily
      ? `${window.location.origin}`
      : `${window.location.origin}/?mode=unlimited&gameId=${currentId}`;
    const shareText = `Quartiles ${isDaily ? 'Daily' : `#${currentId}`}\nScore: ${score}\nRank: ${rank}\n${shareUrl}`;
    navigator.clipboard.writeText(shareText);
    alert('Copied! You can now share the result with others!')
  };

  const handleKeepPlaying = () => {
    if (isDaily) {
      setShowResults(false);
    } else {
      const newGameId = Math.floor(Math.random() * 5000) + 5000;
      window.location.href = `/?mode=unlimited&gameId=${newGameId}`;
    }
  };

  if (!puzzle) return (
    <div className="max-w-md mx-auto bg-white rounded-lg shadow">
      <div className="flex flex-col items-center justify-center min-h-[200px] p-8">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-emerald-500 border-t-transparent"></div>
        <div className="mt-4 text-gray-600">Loading your puzzle...</div>
      </div>
    </div>
  );

  return (
    <div className="max-w-md mx-auto">
      <style>{styles}</style>
      
      {/* Header */}
      <div className="bg-emerald-600 text-white rounded-lg p-4">
        <div className="flex justify-between items-center">
          <div className="text-4xl font-bold">{score}</div>
          <div>Quartiles {quartilesFound.length}/5</div>
        </div>
        {!isDaily && (
          <div className="mt-2 text-sm text-center">
            Game #{gameId}
          </div>
        )}
      </div>
  
      {/* Word Display Area */}
      <div className="bg-white p-2">
        <div className="mb-2">
            <div className="bg-gray-50 p-3 rounded-lg text-center min-h-12">
            <div className="flex flex-col items-center justify-center gap-2">
                
                {selectedTiles.length > 0 ? (
                <div className="flex gap-1">
                    {selectedTiles.map((tile, index) => (
                    <div 
                        key={index}
                        className="bg-emerald-500 text-white px-3 py-1 rounded-lg shadow"
                    >
                        {tile}
                    </div>
                    ))}
                    <button 
                    onClick={() => setSelectedTiles([])}
                    className="ml-1 text-gray-400 hover:text-gray-600"
                    >
                    ×
                    </button>
                </div>
                ) : wordsFound.length > 0 ? (
                <div className="flex items-center gap-2">
                    <span className="font-medium">{wordsFound[wordsFound.length - 1]}</span>
                    <button className="text-gray-400">▼</button>
                </div>
                ) : (
                <span className="text-gray-500">Select tiles to form words</span>
                )}
            </div>
            </div>
        </div>
  
        {/* Tiles Area */}
        <div className="relative mb-4">
          {showWordEffect && (
            <div className="absolute inset-0 flex items-center justify-center z-10">
              <div className="bg-emerald-500 text-white px-4 py-2 rounded-lg shadow-lg relative animate-appear">
                {currentWord}
                <CelebrationRays />
              </div>
            </div>
          )}
  
          {/* Active Tiles Grid */}
          <div className="tile-grid">
    {activeTiles.map((tile, index) => (
      <button
        key={`active-${index}`}
        onClick={() => handleTileClick(tile)}
        className={`p-2 rounded-lg shadow transition-all duration-200 ${
          selectedTiles.includes(tile)
            ? 'bg-emerald-500 text-white transform scale-105'
            : 'bg-gray-100 hover:bg-gray-200'
        }`}
      >
        {tile}
      </button>
    ))}
    {/* Add empty placeholders to complete the grid */}
    {[...Array(20 - activeTiles.length)].map((_, i) => (
      <div key={`empty-${i}`} className="p-2" />
    ))}
  </div>
  
          {/* Quartile Tiles - Now positioned in existing grid */}
            {/* Quartile Tiles */}
            {quartileTiles.length > 0 && (
                <div className="tile-grid mt-2">
                {quartileTiles.map((tile, index) => (
                    <button
                    key={`quartile-${index}`}
                    className="p-2 rounded-lg shadow quartile-tile bg-white"
                    disabled
                    >
                    {tile}
                    </button>
                ))}
                {/* Add empty placeholders to maintain 4 columns */}
                {[...Array(4 - (quartileTiles.length % 4))].map((_, i) => (
                    <div key={`placeholder-${i}`} className="p-2" />
                ))}
                </div>
                    )}
        </div>
  
        {/* Control Buttons */}
        <div className="flex justify-between">
            <button
                onClick={shuffleTiles}
                className="w-10 h-10 flex items-center justify-center rounded-full bg-gray-100 hover:bg-gray-200"
            >
                <FontAwesomeIcon icon={faShuffle} />
            </button>
            <button
                onClick={handleSubmit}
                className="w-10 h-10 flex items-center justify-center rounded-full bg-emerald-500 text-white hover:bg-emerald-600"
            >
                <FontAwesomeIcon icon={faCheck} />
            </button>
            </div>
  
        {/* New Game Button (Unlimited Mode) */}
        {!isDaily && <NewGameButton />}
      </div>
  
      {/* Words Found List */}
      <div className="p-4">
        <span className="font-bold mb-2">Words Found:</span>
        <div className="flex flex-wrap gap-2">
          {wordsFound.map((word, index) => (
            <div key={index} className="bg-emerald-100 rounded-lg px-3 py-1">
              {word}
            </div>
          ))}
        </div>
        
      </div>
  
      {/* Results Modal */}
      {showResults && (
        <ResultsModal
          score={score}
          rank={rank}
          wordsFound={wordsFound}
          streak={gameStats.streak}
          onClose={() => setShowResults(false)}
          onShare={handleShare}
          onKeepPlaying={handleKeepPlaying}
          isDaily={isDaily}
          gameId={gameId}
        />
      )}
      {/* Stats Modal */}
      {showStats && (
        <StatsModal
          stats={gameStats}
          onClose={() => setShowStats(false)}
        />
      )}
    </div>

  );

}

  // Generate game ID helper (outside component)
const generateGameId = (isDaily: boolean): number => {
    if (isDaily) {
      const start = new Date('2024-12-04').getTime();
      const now = new Date().getTime();
      return Math.floor((now - start) / (1000 * 60 * 60 * 24)) + 1;
    } else {
      return Math.floor(Math.random() * 5000) + 5000;
    }
  };

  export default Game;